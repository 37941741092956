<template>
  <main>
    <top-view cn="乐享简介" en="The Company Of Lohas Plus" :img="require('../../assets/img/bg_01@2x.jpg')"/>

    <div class="intro-view">
      <div class="intro-view__bg"></div>
      <div class="intro-view__text-container">
        <div class="intro-view__text-decoration"></div>
        <p>从2000年开始，乐享无限集团透过近二十年的FP（financial planning）财务规划专业知识，建立了FP财务规划行业的独特成功模式——培养专业的FP专才，透过LPPP以及ELPPP人生规划四部曲帮助大众做好个人及家庭的财务规划，达致财务自由。</p>
        <p>乐享无限的APP透过线上专家团队精彩的内容分享，线下私属空间大咖丰富的课程讲授，协助大众提升财务规划的专业知识，同时提供一系列专业的资产增值计划。</p>
        <p>乐享无限致力于用专业培养真正的FP专才，用专业改变世界，用专业使您达成财务自由，最终享受乐活、分享、品味的快乐人生。</p>
      </div>
      <div class="intro-view__img"></div>
    </div>

    <!--使命愿景-->
    <div class="title-view title-view--with-line">
      <normal-title cn="使命愿景" en="Mission vision"/>
      <div class="title-view__line"></div>
    </div>
    <div class="mission-view__text-container">
      <p>乐享无限集团致力于推动财富管理全球化，研究和推广全球适用的财富管理</p>
      <p>职业知识体系和认证标准。为各地区培养现代金融行业管理所必须的专业理财、风险管理、</p>
      <p>投资管理、资产管理等方面的中高级专业人才。</p>
    </div>

    <!--关于乐享-->
    <div class="title-view">
      <normal-title cn="关于乐享" en="About Lohas Plus"/>
    </div>
    <section class="about-view__section about-view__section--1">
      <div class="image"></div>
      <div class="about-view__section-text">
        <normal-title cn="我们的核心理念" en="Our Core Philosophy"/>
        <p>您的终身财富管家，我的终身事业</p>
        <p>通过11年的专业行业知识，培养优秀FP专才</p>
        <p>完善自我的职业生涯规划</p>
        <p>做好个人以及家庭的财富管理</p>
      </div>
    </section>
    <section class="about-view__section about-view__section--2">
      <div class="about-view__section-text">
        <normal-title cn="我们的使命" en="Our Mission"/>
        <p>用我们的专业为您培育真正FP专才</p>
        <p>用我们的专业使您达成财务自由</p>
        <p>用我们的专业让您实现乐活的人生</p>
        <p>用我们的专业改变世界</p>
      </div>
      <div class="image"></div>
<!--      <img src="../../assets/img/pic_10@2x.png" alt="我们的使命">-->
    </section>

<!--发展历程-->
    <div class="title-view title-view--with-line dev-view__title">
      <normal-title cn="发展历程" en="Development History"/>
      <div class="title-view__line title-view__line--development"></div>
    </div>

    <div class="dev-view">
      <img class="dev-view__logo" src="../../assets/img/logo_05@2x.png" alt="乐享无限logo">
      <div class="dev-view__btn-container">
        <div class="dev-view__btn" @click="prev"><i></i></div>
        <div class="dev-view__btn" @click="next"><i></i></div>
      </div>
<!--轮播器-->
      <div class="dev-view__carousel-container--outer">
        <div class="dev-view__carousel-line"></div>
        <div class="dev-view__carousel-container" :style="{left: carouselLeft + 'px'}">
          <div class="dev-view__carousel-item" v-for="item in history" :key="item.text">
            <div class="dev-view__carousel-year">
              <p>{{item.year}}</p>
            </div>
            <div class="dev-view__carousel-text-container">
              <span>{{item.title || '乐享无限'}}</span>
              <i></i>
              <p>{{item.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import NormalTitle from "../../components/common/NormalTitle"
  import history from "../../assets/json/history.json"
  import TopView from "../../components/common/TopView"
  export default {
    name: "Introduction",
    data() {
      return {
        // 轮播滚动距离
        carouselLeft: - (history.length - 5) * 240,
        history: history
      }
    },
    components: {
      NormalTitle,
      TopView
    },
    metaInfo: {
      title: '乐享无限官网-乐享集团',
      meta: [
        {
          name: 'keyWords',
          content: '乐享无限介绍,乐享无限是什么,乐享无限怎么样,乐享无限官网,乐享无限综合性自我增值平台,智菁通,财务规划',
        },
        {
          name: 'description',
          content: '乐享无限是什么?乐享无限集团致力于推动财富管理全球化,研究和推广全球适用的财富管理职业知识体系和认证标准。乐享无限集团为各地区培养现代金融行业管理所必须的专业理财、风险管理、投资管理、资产管理等方面的中高级专业人才。'
        }
      ]
    },
    methods: {
      // 一次滑5个
      next() {
        // if(this.carouselLeft <= - (this.history.length - 5) * 240) return
        let current = - (this.carouselLeft / 240 - 5) // 15
        let length = this.history.length // 15
        if(current === length) return // 到头了

        let times = 5
        if(length - current < 5) {
          times = length - current
        }
        this.carouselLeft = this.carouselLeft - 240 * times
      },
      prev() {
        let current = - (this.carouselLeft / 240)
        if(current <= 0) return // 到头了

        let times = 5
        if(current < 5) {
          times = current
        }
        this.carouselLeft = this.carouselLeft + 240 * times
      }
    },
  }
</script>

<style lang="scss" scoped>
.intro-view {
  max-width: 1340px;
  width: 80%;
  height: 490px;
  margin: 90px auto;
  position: relative;
  @include clearfix;
}

// 橘黄色的背景矩形
.intro-view__bg {
  height: 460px;
  width: 100%;
  background-color: #f39800;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
// 左侧文字
.intro-view__text-container {
  background-color: #ffffff;
  height: 400px;
  width: 456px;
  box-sizing: border-box;
  margin: 14px + 30px 0 0 16px;
  padding: 20px 40px 40px 40px;
  text-align: justify;
  font-size: 18px;
  color: #333333;
  float: left;
}
.intro-view__text-decoration {
  height: 9px;
  width: 30px;
  background-color: #f39800;
  margin-bottom: 42px;
}
// 右侧图片
.intro-view__img {
  display: block;
  float: left;
  margin-left: 20px;
  max-width: 833px;
  width: calc(100% - 456px - 16px - 20px - 20px);
  height: 468px;
  background: url("../../assets/img/pic_08@2x.jpg") no-repeat center;
}

// 使命愿景
.title-view {
  max-width: 1340px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
}

.title-view--with-line {
  .normal-title {
    display: inline-block;
  }
}
.title-view__line {
  height: 3px;
  flex: 1;
  background-color: #999999;
  display: inline-block;
  margin-left: 20px;
}
.mission-view__text-container {
  height: 200px;
  width: 100%;
  background: url("../../assets/img/bg_02@2x.png") no-repeat center center;
  font-size: 18px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
  box-sizing: border-box;
  padding-top: 45px;
  margin: 44px 0 80px;
}

// 关于乐享
.about-view__section {
  max-width: 1180px;
  width: 80%;
  margin: 0 auto;
  display: flex;

  .about-view__section-text {
    ::v-deep .normal-title__en {
      text-indent: 4em;
      color: #999999;
    }
    ::v-deep .normal-title__cn {
      font-size: 34px;
    }
    .normal-title {
      margin-bottom: 80px;
    }
    p {
      font-size: 18px;
      line-height: 30px;
      color: #0e0e0e;
    }
  }
}
.about-view__section--1 {
  margin-top: 50px;
  .image {
    height: 340px;
    max-width: 700px;
    width: calc(100% - 480px - 40px);
    background: url("../../assets/img/pic_09@2x.png") no-repeat right;
    margin-left: 40px;
  }
  .about-view__section-text {
    width: 340px;
    margin-left: 140px;
  }
}
.about-view__section--2 {
  .image {
    height: 340px;
    max-width: 700px;
    width: calc(100% - 280px - 140px);
    background: url("../../assets/img/pic_10@2x.png") no-repeat center;
  }
  margin-top: 90px;
  .about-view__section-text {
    width: 340px;
    margin-left: 110px;
    margin-right: 140px;
  }
}

// 发展历程
.dev-view__title {
  margin: 80px auto 40px;
}
.title-view__line--development {
  width: 1000px;
}

.dev-view {
  @include clearfix;
  width: 100%;
  height: 693px;
  background: url("../../assets/img/bg_03@2x.jpg") no-repeat center center;
  background-size: cover;
}
.dev-view__logo {
  display: block;
  margin: 73px auto 40px;
}
.dev-view__carousel-container--outer {
  width: 1140px;
  height: 395px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}
.dev-view__carousel-line {
  width: 960px;
  height: 1px;
  background-color: #f39800;
  position: absolute;
  top: 47.5px;
  left: 90px;
  z-index: 9;
}
.dev-view__carousel-container {
  width: 5000px;
  height: 395px;
  @include clearfix;
  transition: all 1s;
  position: absolute;
}
.dev-view__carousel-item {
  width: 180px;
  float: left;
  margin-right: 60px;
  &:last-child {
    margin: 0;
  }
}
.dev-view__carousel-year {
  position: relative;
  z-index: 10;
  width: 95px;
  height: 95px;
  margin: 0 auto;
  background: url("../../assets/img/icon_circle@2x.png") no-repeat 100% 100%;
  @include clearfix;
  p {
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.dev-view__carousel-text-container {
  margin-top: 30px;
  background-color: #00000066;
  width: 180px;
  height: 270px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  color: #ffffff;
  span {
    display: block;
    margin: 15px 0 10px;
    font-size: 18px;
  }
  i {
    display: block;
    width: 18px;
    height: 6px;
    background-color: #f39800;
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
    line-height: 30px;
  }
}

// 按钮
.dev-view__btn-container {
  max-width: 1340px;
  width: 97.5%;
  margin: 0 auto;
  position: relative;
}
.dev-view__btn {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #f39800;
  cursor: pointer;
  position: absolute;
  top: 22.5px;
  i {
    display: block;
    height: 18px;
    width: 18px;
    border-top: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    position: absolute;
    top: 15px;
  }
  &:first-child {
    left: 0;
    i {
      transform: rotate(-45deg);
      left: 20px;
    }
  }
  &:last-child {
    right: 0;
    i {
      transform: rotate(135deg);
      left: 12px;
    }
  }
}
</style>
